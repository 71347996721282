import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"

import Container from "../components/Container"

function LegalPage(props) {
  const { data } = props
  const page = data.legalPage

  return (
    <Container inset>
      <Content dangerouslySetInnerHTML={{ __html: page.html }} />
    </Container>
  )
}

const blockquote = css`
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: ${({ theme }) => "blue"};
  color: ${({ theme }) => theme.colors.gray[700]};
`

const Content = styled.section`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.base};
  margin: 0;
  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.fontFamily.headings};
  }
  & h1 {
    font-size: ${({ theme }) => theme.fontSize["2xl"]};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
    margin-top: ${({ theme }) => theme.spacing[3]};
  }
  & h2 {
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  }
  & h3 {
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  }
  p {
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
  }
  & > blockquote {
    ${blockquote}
  }
  & > ol {
    counter-reset: ollist;
    display: table;
    border-spacing: 0 ${({ theme }) => theme.spacing[4]};
    margin-top: -${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
  & > ol > li {
    counter-increment: ollist;
    display: table-row;
  }
  & > ol > li::before {
    content: counter(ollist) ".";
    display: table-cell;
    text-align: right;
    padding-right: ${({ theme }) => theme.spacing[2]};
    padding-left: ${({ theme }) => theme.spacing[0]};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    & > ol > li::before {
      padding-right: ${({ theme }) => theme.spacing[4]};
      padding-left: ${({ theme }) => theme.spacing[4]};
    }
  }
`

export default LegalPage

export const postQuery = graphql`
  query($id: String!) {
    legalPage: ghostPage(id: { eq: $id }) {
      slug
      title
      id
      html
    }
  }
`
